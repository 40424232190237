export const Business = Object.freeze({
    name: "Strange Music Box",
    address: "225 NW Victoria Dr",
    city: "Lees Summit",
    state: "MO",
    zip: "64086",
    phoneNumber: "(816) 434-7001",
    email: "info@strangemusicbox.com",
    url: "https://www.strangemusicbox.com",
    facebook: "https://www.facebook.com/Strange-Box-560544967731208/",
    twitter: "https://twitter.com/strangebox_",
    instagram: "https://www.instagram.com/strangebox_/",
    youtube: "https://www.youtube.com/channel/UCVwQjClG4X-PRektezmvnbA?view_as=subscriber"
})